/*** Importations ******************************************************************************/

// Polyfills

import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/Object.assign';

import 'classlist-polyfill';
// import 'unfetch/polyfill';
import focusWithin from 'focus-within-polyfill/src/focus-within.js';
import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import smoothscroll from 'smoothscroll-polyfill';


// Libraries

import InfiniteScroll from 'infinite-scroll';
// import fullpage from 'fullpage.js';
// import 'owl.carousel';
import LazyLoad from 'vanilla-lazyload/dist/lazyload.esm.js';
// import * as basicLightbox from 'basiclightbox/src/scripts/main.js';
import { CountUp } from 'countup.js';
import { tns } from 'tiny-slider/src/tiny-slider.module.js';
import Glide from '@glidejs/glide/dist/glide.esm.js';
import tippy from 'tippy.js/dist/tippy.esm.js';
import GLightbox from 'glightbox/dist/js/glightbox.js';
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';


// Scripts Nubee

import * as functions from 'partials/functions.js';
import GoogleMap from 'partials/google-map.js';
import MobileMenu from 'partials/mobile-menu.js';
// import PageMenu from 'partials/page-menu.js';
// import toggleSearchForm from 'partials/toggle-search-form.js';
import imgCheck from 'partials/img-check.js';
// import AjaxPopup from 'partials/ajax-popup.js';
// import NubeeLightbox from 'partials/nubee-lightbox.js';
import placeholderLabelUpdate from 'partials/placeholder-label.js';
import FormValidation from 'partials/form-validation.js';
// import SwipeFinger from 'partials/swipe-finger.js';
import Tabs from 'partials/tabs.js';
import * as cookiesBar from 'partials/cookies-bar.js';

(function() {
	/*** Application des polyfills *****************************************************************/

	(function() {
		objectFitImages();
		smoothscroll.polyfill();

		if( 'function' != typeof window.Event ) {
			window.Event = CustomEvent;
		}
	}) ();


	/*** Variables globales ************************************************************************/

	window.vWidth = document.documentElement.clientWidth;
	window.vHeight = document.documentElement.clientHeight;

	window.scrollDirection = null;
	functions.calcFixedEls();

	// window.pageMenus = [];

	window.vWidthExceptFixed = document.documentElement.clientWidth - window.fixedElWidth;
	window.vHeightExceptFixed = window.vHeight - window.fixedElHeight;
	window.curScroll = {
		top: document.documentElement.scrollTop || document.body.scrollTop || 0,
		left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
		topExceptFixed: ( document.documentElement.scrollTop || document.body.scrollTop || 0 ) + window.fixedElHeight,
		leftExceptFixed: ( document.documentElement.scrollLeft || document.body.scrollLeft || 0 ) + window.fixedElWidth,
	};
	window.vCenter = {
		x: window.curScroll.left + ( window.vWidth / 2 ),
		y: window.curScroll.top + ( window.vHeight / 2 ),
		xExceptFixed: window.curScroll.left + ( window.vWidthExceptFixed / 2 ),
		yExceptFixed: window.curScroll.top + ( window.vHeightExceptFixed / 2 ),
	};
	window.ga = window.ga || window._gaq || null;
	window.lastWindowOpened = 0;
	window.scrollDirection = null;

	// countUp

	window.countUpNumbers = Array.prototype.slice.call( document.querySelectorAll( '.countup-number' ) );
	window.countUpInstances = [];


	// Chaîne traduisible : window.bonesGlobals.i18n.monPlugin.nomDeChaine


	/*** Mise à jour des mesures de l'écran au resize *********************************************/

	window.addEventListener( 'resize', function() {
		window.vWidth = document.documentElement.clientWidth;
		window.vHeight = document.documentElement.clientHeight;
		window.curScroll.top = document.documentElement.scrollTop || document.body.scrollTop;
		window.vCenter.y = window.curScroll.top + ( window.vHeight / 2 );

		window.headerHeight = document.querySelector('.site-header').offsetHeight;
	} );


	/*** Les liens avec ancre qui mènent vers la même page deviennent seulement des ancres ****************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linksWithHash = document.querySelectorAll('a[href*="#"]');

		linksWithHash.forEach( function( thisLink ) {
			if( functions.urlsAreSamePage({ urls: [ thisLink.href, window.location.href ] }) ) {
				thisLink.href = thisLink.hash;
			}
		} );
	}) ();


	/*** Défiler doucement les liens ancres ****************************************************************/
	// Requiert la fonction functions.smoothScrollTo
	// Ajuster la variable globale window.fixedElHeight

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			document.querySelectorAll('a[href^="#"]:not([href="#"])').forEach( function( thisEl ) {
				thisEl.addEventListener( 'click', function( event ) {
					if( 1 == event.which && ! this.classList.contains('no-smooth-scroll') ) {
						let theHash;

						event.preventDefault();
						theHash = this.getAttribute('href');
						functions.smoothScrollTo({ target: theHash });
					}
				} );
			});
		}
	}) ();


	/*** Ne rien faire au clic sur un lien vide (#) *********************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest('a');
				let theHref = linkClicked ? linkClicked.getAttribute('href') : null;

				if( linkClicked && ( ! theHref || '#' == theHref ) ) {
					event.preventDefault();
				}
			}
		} );
	}) ();


	/*** Images et backgrounds différents selon la taille de l'écran *****************************/
	// Requiert img-check.js

	(function() {
		if( 'function' == typeof imgCheck ) {
			window.addEventListener( 'resize', imgCheck );
			imgCheck();
		}
	}) ();


	/*** Lazy load des images et backgrounds ****************************************************************/
	// Requiert vanilla-lazyload

	(function() {
		window.siteLazyLoad = new LazyLoad({
			elements_selector: '.lazy',
			class_loading: 'lazy-loading',
			class_loaded: 'lazy-loaded',
			class_error: 'lazy-error',
			callback_loaded: function( element ) {
				element.style.paddingTop = '';
			},
		});
	}) ();


	/*** Sécurité pour les liens "nouvel onglet" ***********************************************************/


	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which || 2 == event.which ) {
				let linkClicked = event.target.closest('a');
				let linkTarget = linkClicked ? linkClicked.getAttribute('target') : null;
				let newWindow;
				let targetRegex = new RegExp( /^(_blank|_new)$/, 'i' )

				if( linkTarget && targetRegex.test( linkTarget ) ) {
					let linkUrl = linkClicked.getAttribute('href');

					event.preventDefault();
					newWindow = window.open( linkClicked.getAttribute('href'), 'targetBlankWindow_' + window.lastWindowOpened );

					++ window.lastWindowOpened;

					if( newWindow ) {
						newWindow.opener = null;
					}
				}
			}
		} );
	}) ();


	/*** Google Maps ***************************************************************************************/
	// Requiert google-map.js

	(function() {
		let mapParents = document.querySelectorAll('.the-map');
		// https://snazzymaps.com/style/53/flat-map
		// Modifié
		let mapStyles = [];

		mapParents.forEach( function( thisParent ) {
			let map = new GoogleMap({
				mapParent: thisParent,
				styles: mapStyles,
				markerIconUrl: window.bonesGlobals.themeDir + '/library/images/map-marker.png'
			});
		} );
	}) ();


	/*** Antispam maison sur les commentaires *************************************************************/
	// À moins d'avoir activé le JS, la validation PHP refusera le commentaire.

	(function() {
		let commentForms = document.querySelectorAll('#commentform');

		commentForms.forEach( function( thisForm ) {
			thisForm.addEventListener( 'submit', function() {
				let legitInput = document.createElement( 'input' );
				legitInput.setAttribute( 'type', 'hidden' );
				legitInput.setAttribute( 'name', 'is_legit' );
				legitInput.value = 1;

				thisForm.appendChild( legitInput );
			} );
		} );
	}) ();


	/*** Validation de formulaire *************************************************************************/
	(function() {
		document.querySelectorAll( '.validate-form' ).forEach( function( thisForm ) {
			let formValidation = new FormValidation({
				form: thisForm,
			});
		} );
	}) ();


	/*** Placeholder labels - Un placeholder qui se tasse quand on écrit **********************************/
	// Requiert la classe sweet-form sur le formulaire
	// Requiert placeholder-label.js

	(function() {
		document.querySelectorAll('.has-placeholder-label input, .has-placeholder-label textarea').forEach( function( thisEl ) {
			placeholderLabelUpdate( thisEl );

			[ 'load', 'focus', 'blur', 'change', 'input' ].forEach( function( eventName ) {
				thisEl.addEventListener( eventName, placeholderLabelUpdate );
			} );
		} );
	}) ();


	/*** Faire afficher le nom du fichier dans les champs input stylisés **********************************/
	(function() {
		let inputs = document.querySelectorAll( '.hidden-file-input' );
		Array.prototype.forEach.call( inputs, function( input ) {
			let label	 = input.nextElementSibling.querySelector('.file-input-label'),
				labelVal = label.innerHTML;

			input.addEventListener( 'change', function( e ) {
				let fileName = '';
				if( this.files && this.files.length > 1 ) {
					fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
				} else {
					fileName = e.target.value.split("\\").pop();
				}

				if( fileName ) {
					label.innerHTML = fileName;
				} else {
					label.innerHTML = labelVal;
				}
			});
		});
	}) ();

	/*** Retour en haut ***********************************************************************************/
	// Requiert functions.smoothScrollTo

	/*(function() {
		document.querySelector('.back-to-top').addEventListener( 'click', function() {
			if( 1 == event.which ) {
				functions.smoothScrollTo({ target: document.body });
			}
		} );
	}) ();*/


	/*** Menu ******************************************************************/
	let mobileMenuButtons = document.querySelectorAll('.mobile-menu-button'),
		menu = document.querySelector('.mobile-menu'),
		body = document.querySelector('body');

	for( const mobileMenuButton of mobileMenuButtons ) {
		mobileMenuButton.addEventListener( 'click', function() {
			let vWidth = window.vWidth,
				toggleMobileMenuButtons = document.querySelectorAll('.mobile-menu-button');

			body.style.overflowY = 'hidden';
			menu.style.overflowY = 'scroll';

			if(mobileMenuButton.classList.contains('open')) {
				menu.style.overflowY = 'hidden';
				body.style.overflowY = '';
			}

			for (const toggleMobileMenuButton of toggleMobileMenuButtons) {
				toggleMobileMenuButton.classList.toggle('open');
			}

			menu.classList.toggle('open');
		});
	}

	/*** Dropdown du menu ******************************************************************/
	if( vWidth < 700 ) {
		let dropdownMenus = document.querySelectorAll('.primary-menu .dropdown');

		for( const dropdownMenu of dropdownMenus ) {
			dropdownMenu.addEventListener( 'click', function(event) {
				let actualMenu = dropdownMenu.getElementsByClassName('dropdown-menu');

				if( event.target.closest('.menu-link') != null ) {
					return;
				}

				actualMenu[0].classList.toggle('open');
			});
		}
	}


	/*** Regroupement d'évènements à intervalles réguliers ************************************************/
	// Plus performant qu'un évènement scroll

	(function() {
		let illustratedSliderCards = document.querySelectorAll('.slider-nav-cards'),
			illustratedSliderCardsProps = [];

		illustratedSliderCards.forEach(function( thisSlider, index ){
			let thisSliderTop = functions.getElementOffset(thisSlider).top - 75,
				thisSliderHeight = thisSlider.offsetHeight + 75;

			illustratedSliderCardsProps[index] = {
				top: thisSliderTop,
				bottom: thisSliderTop + thisSliderHeight,
				height: thisSliderHeight,
				currentClass: 'scroll-0'
			};
		});

		window.scrollInterval = setInterval(function() {
			let lastScroll = window.curScroll;

			window.curScroll = {
				top: document.documentElement.scrollTop || document.body.scrollTop || 0,
				left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
				bottom: ( document.documentElement.scrollTop || document.body.scrollTop || 0 ) + window.vHeight,
			};
			window.vCenter = {
				x: window.curScroll.left + ( window.vWidth / 2 ),
				y: window.curScroll.top + ( window.vHeight / 2 ),
			};

			if( window.curScroll.top != lastScroll.top ) {
				// window.pageMenus.forEach( function( thisMenu ) {
				// 	thisMenu.update();
				// } );

				if( window.curScroll.top > lastScroll.top ) {
					window.scrollDirection = 'down';
					document.body.classList.add('scrolling-down');
					document.body.classList.remove('scrolling-up');
					window.scrollDirection = 'down';
				} else if( window.curScroll.top < lastScroll.top ) {
					window.scrollDirection = 'up';
					document.body.classList.add('scrolling-up');
					document.body.classList.remove('scrolling-down');
					window.scrollDirection = 'up';
				}
			}

			// Classes sur le body pour faire changer des CSS en fonction du scroll

			if( window.curScroll.top > 550 ) {
				document.body.classList.add('scrolled-a-lot');
			} else {
				document.body.classList.remove('scrolled-a-lot');
			}

			if( window.curScroll.top > 120 ) {
				document.body.classList.add('scrolled-a-bit');
			} else {
				document.body.classList.remove('scrolled-a-bit');
			}

			// CountUp

			if( 'undefined' != typeof CountUp && 0 < countUpInstances.length ) {
				// console.log(countUpInstances);
				countUpNumbers.forEach( function( element, index, iteratedArray ) {
					if( element && functions.isInViewport(element) ) {
						if( ! countUpInstances[index].error ) {
							element.classList.add( 'countup-counting', 'countup-init' );

							countUpInstances[index].start( function() {
								element.classList.add( 'countup-counted' );
								element.classList.remove( 'countup-counting' );
							} );
						} else {
							console.error( countUpInstances[index].error );
						}

						// On vient de lancer CountUp sur cet élément. On l'enlève de la liste des éléments à traiter.
						iteratedArray[index] = null;
					}
				} );
			}

			if( window.vWidth >= 921 ) {
				illustratedSliderCards.forEach(function( thisSlider, index ){
					// Faire apparaître les cards des caroussels illustrés

					let windowBottomScroll = window.curScroll.bottom,
						currentScroll = window.curScroll.bottom - illustratedSliderCardsProps[index].top,
						animationHeight = illustratedSliderCardsProps[index].height;

					if( windowBottomScroll >= illustratedSliderCardsProps[index].top && windowBottomScroll < illustratedSliderCardsProps[index].bottom ) {
						let progressionPercent = Math.ceil( currentScroll * 100 / animationHeight ),
							nextDecade = Math.ceil( ( progressionPercent + 1 ) / 10 ) * 10,
							currentClass;

						nextDecade = nextDecade <= 100 ? nextDecade : 100;
						currentClass = 'scroll-' + nextDecade;

						if( !thisSlider.classList.contains(currentClass) ) {
							thisSlider.classList.remove( illustratedSliderCardsProps[index].currentClass );
							thisSlider.classList.add(currentClass);
							illustratedSliderCardsProps[index].currentClass = currentClass;
						}
					} else if( windowBottomScroll < illustratedSliderCardsProps[index].top && !thisSlider.classList.contains('scroll-0') ) {
						thisSlider.classList.remove( illustratedSliderCardsProps[index].currentClass );
						thisSlider.classList.add('scroll-0');
						illustratedSliderCardsProps[index].currentClass = 'scroll-0';
					} else if( windowBottomScroll > illustratedSliderCardsProps[index].bottom && !thisSlider.classList.contains('scroll-100') ) {
						thisSlider.classList.remove( illustratedSliderCardsProps[index].currentClass );
						thisSlider.classList.add('scroll-100');
						illustratedSliderCardsProps[index].currentClass = 'scroll-100';
					}

				});
			}

			/*** smoothFadeIn - effet d'apparition lorsque dans le viewport *****************************************/
			// Requiert functions.smoothFadeIn
			// Options dans general-styles.scss

			let fadeInItems = document.querySelectorAll('.smooth-fade-in');
			fadeInItems.forEach( function( thisEl ) {
				functions.smoothFadeIn( thisEl );

				window.addEventListener( 'scrollinterval', function() {
					functions.smoothFadeIn( thisEl );
				} );
			} );


			// Recalcul des éléments fixes (qui influent sur les smoothScrollTo)
			functions.calcFixedEls();
		}, 100 );
	}) ();


	/*** Recherche qui s'ouvre/ferme en cliquant sur la loupe *********************************************/
	// Requiert toggle-search-form.js

	/*
	(function() {
		if( 'undefined' !== typeof toggleSearchForm ) {
			document.querySelectorAll('.search-form .search-button').forEach( function( thisEl ) {
				thisEl.addEventListener( 'click', function() {
					if( 1 == event.which ) {
						if( ! thisEl.closest('.search-form').classList.contains('open') ) {
							toggleSearchForm( event );
						} else {
							thisEl.closest('form').submit();
						}
					}
				} );
			} );
		}
	}) ();
	*/



	/*** Infinite Scroll - Charger les prochains articles automatiquement *******************************/
	// Requiert jQuery
	// Requiert infinite-scroll.pkgd.min.js

	(function() {
		let infiniteContainers = document.querySelectorAll('.infinite-scroll');

		infiniteContainers.forEach(function( thisEl ) {
			if( thisEl.parentNode.querySelector('.pagination .next') ) {
				let infiniteInstance = new InfiniteScroll(
					'.infinite-scroll',
					{
						path: '.pagination .next',
						button: '.infinite-scroll-more',
						append: '.infinite-scroll-element',
						prefill: false,
						history: false,
						// outlayer: outlayer,
						hideNav: '.pagination',
						status: '.infinite-scroll-status',
						debug: false,
						scrollThreshold: false,
					}
				);

				infiniteInstance.on( 'append', function( event, response, path, items ) {
					thisEl.parentNode.querySelector('.infinite-scroll-status').style.display = 'none';
					imgCheck();
					window.siteLazyLoad.update();
				} );

				infiniteInstance.on( 'last', function( event, response, path, items ) {
					thisEl.parentNode.querySelector('.plus-link-wrapper').style.display = 'none';
				} );
			}
		});
	}) ();


	/*** Dropdown de médias sociaux ***********************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let buttonClicked = event.target.closest('.more-networks-button');
				let theContainer;
				let theDropdown;

				if( buttonClicked ) {
					let theContainer = buttonClicked.closest( '.more-networks' );
					let theDropdown = theContainer.querySelector( '.more-networks-dropdown' );

					if( ! theContainer.classList.contains('animating') ) {
						theContainer.classList.add( 'animating' );

						if( ! theContainer.classList.contains( 'open' ) ) {
							theContainer.classList.add( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeInOver( event ) {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeInOver );
							} );

						} else {
							theContainer.classList.remove( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeOutOver( event ) {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeOutOver );
							} );
						}
					}
				}
			}
		} );
	}) ();


	/*** Sliders Glide *************************************************************************************/
	// Requiert Glide (@glidejs/glide)
	// Ce slider n'a pas de fonction tout-en-un disponible.

	// link-slider
	(function() {
		let linkSliders = document.querySelectorAll('.page-block-link-slider .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			let sliderOptions = {
				type: 'slider',
				perView: 2,
				gap: 30,
				bound: true,
				rewind: true,
				peek: {
					before: 0,
					after: 0,
				},
				breakpoints: {
					1400: {
						perView: 2,
						peek: {
							before: 0,
							after: 80,
						},
					},
					1000: {
						perView: 2,
						peek: {
							before: 0,
							after: 50,
						},
					},
					700: {
						perView: 1,
						peek: {
							before: 0,
							after: 40,
						},
					},
					500: {
						perView: 1,
						gap: 20,
						peek: {
							before: 0,
							after: 20,
						},
					},
				},
			};

			let sliderInstance = new Glide( sliderEl, sliderOptions );

			sliderInstance.on( 'move', function( moveAmount ) {
				window.siteLazyLoad.update();
				sliderEl.classList.add( 'slider-animating' );
			} );

			sliderInstance.on( 'move.after', function( moveAmount ) {
				window.siteLazyLoad.update();
				sliderEl.classList.remove( 'slider-animating' );
			} );

			document.addEventListener( 'click', function( event ) {
				let inactiveOverlay = event.target.closest( '.inactive-overlay' );

				if( inactiveOverlay ) {
					event.preventDefault();
					sliderInstance.go('>');
				}
			} );

			sliderInstance.mount();
		} );
	}) ();


	// room-slider
	(function() {
		let linkSliders = document.querySelectorAll('.page-block-room-slider .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			let sliderOptions = {
				type: 'slider',
				perView: 1,
				gap: 0,
				bound: true,
				rewind: true,
			};

			let sliderInstance = new Glide( sliderEl, sliderOptions );

			sliderInstance.on( 'move', function( moveAmount ) {
				window.siteLazyLoad.update();
				sliderEl.classList.add( 'slider-animating' );
			} );

			sliderInstance.on( 'move.after', function( moveAmount ) {
				window.siteLazyLoad.update();
				sliderEl.classList.remove( 'slider-animating' );
			} );

			sliderInstance.mount();
		} );
	}) ();




	/*** Sliders Tiny Slider *******************************************************************************/
	// Requiert tns (tiny-slider)
	// Requiert la fonction functions.initSliderTiny

	(function() {
		document.querySelectorAll('.tiny-slider').forEach( function( thisEl ) {
			functions.initSliderTiny( thisEl );
		} );
	}) ();


	/*** Ajustement de la hauteur des éléments plein écran aux barres de navigation iOS etc. ****************/

	(function() {
		window.addEventListener( 'load', function( event ) {
			let fullScreenEls = document.querySelectorAll('.full-screen-height');

			fullScreenEls.forEach( function( thisEl ) {
				thisEl.style.minHeight = ( window.innerHeight - window.fixedElHeight - window.headerHeight ) + 'px';
			} );
		} );
	}) ();


	/*** Empêcher la taille d'un élément de se réajuster une fois le chargement terminé ********************/

	(function() {
		let lockedHeightEls = document.querySelectorAll('.lock-height-on-load');

		window.addEventListener( 'load', function( event ) {
			lockedHeightEls.forEach( function( thisEl ) {
				thisEl.style.height = thisEl.offsetHeight + 'px';
				thisEl.style.minHeight = 0;
				thisEl.style.maxHeight = 'none';
			} );
		} );
	}) ();


	/*** Polyfill pseudo-sélecteur CSS :focus-within *******************************************************/
	// Requiert focus-within
	// Il ne s'active que si le navigateur ne supporte pas le pseudo-sélecteur.

	(function() {
		focusWithin.polyfill();
	}) ();


	/*** Select qui redirigent quand on choisit une option ***************************************************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linkSelects = document.querySelectorAll('.link-select');

		linkSelects.forEach( function( thisSelect ) {
			[ 'change', 'keypress' ].forEach( function( eventName ) {
				thisSelect.addEventListener( eventName, function() {
					let selectedOption = thisSelect.options[ thisSelect.selectedIndex ];
					let targetUrl = selectedOption.dataset.url;

					if( targetUrl ) {
						if( /^#.+/.test( targetUrl ) ) {
							functions.smoothScrollTo({ target: targetUrl });
						} else if( ! functions.urlsAreSamePage({ urls: targetUrl }) ) {
							window.location.href = targetUrl;
						}
					}
				} );
			} );
		} );
	}) ();


	/*** Défiler doucement au chargement d'une URL avec ancre ***********************************************/
	// Ajoute l'évènement "initialscrollend" pour dire que la page est chargée ET l'ancre active est scrollée
	// Requiert la fonction functions.smoothScrollTo

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			let origHash = window.location.hash;
			let scrollEndEvent = new Event( 'initialscrollend' );

			if( origHash ) {
				let targetEl = document.querySelector( origHash );

				if( targetEl ) {
					// Blocage du jump du navigateur par défaut
					setTimeout( function() {
						window.location.hash = '';
						functions.removeHash();
					}, 1 )

					window.addEventListener( 'load', function() {
						window.addEventListener( 'smoothscrollend', function initialScrollEnd() {
							window.dispatchEvent( scrollEndEvent );
							window.removeEventListener( 'smoothscrollend', initialScrollEnd );
						} );

						if( origHash ) {
							functions.smoothScrollTo({
								target: origHash,
							});
						}
					});
				} else {
					window.dispatchEvent( scrollEndEvent );
				}
			} else {
				window.dispatchEvent( scrollEndEvent );
			}
		}
	}) ();


	/*** Donner la même hauteur à un groupe d'éléments *******************************************************/

	(function() {
		window.addEventListener( 'load', function() {
			let sameHeightGroups = document.querySelectorAll('.same-height-group');

			sameHeightGroups.forEach( function( thisGroup ) {
				let minWidth = thisGroup.dataset.sameHeightMinWidth || 0;
				let sameHeightEls = thisGroup.querySelectorAll('.same-height');
				let highestHeight = 0;

				if( window.vWidth >= minWidth ) {
					sameHeightEls.forEach( function( thisEl ) {
						highestHeight = Math.max( thisEl.clientHeight, highestHeight );
					} );

					sameHeightEls.forEach( function( thisEl ) {
						thisEl.style.minHeight = highestHeight + 'px';
					} );
				}
			} );
		} );
	}) ();

	/*** countUp : Statistiques qui montent ******************************************************************/
	// Requiert functions.isInViewport
	// Requiert functions.countDecimals
	// Requiert les variables globales window.countUpNumbers et window.countUpInstances
	// Requiert un code dans le scrollInterval

	(function() {
		countUpNumbers.forEach( function( element, index, iteratedArray ) {
			let endNumber = parseFloat( element.innerHTML );
			let startNumber = Math.max( 0, ( endNumber - 100 ) );
			let nbDecimals = functions.countDecimals( endNumber );
			let duration = 2; // Secondes
			let options = {
				startVal: startNumber,
				decimalPlaces: nbDecimals,
				duration: 2,
				useEasing: true,
				// Générée avec https://inorganik.github.io/countUp.js/
				easingFn: function(t, b, c, d) {
					let ts = (t /= d) * t;
					let tc = ts * t;
					return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
				},
				useGrouping: true,
				separator: window.bonesGlobals.i18n.numbers.thousandSeparator,
				decimal: window.bonesGlobals.i18n.numbers.decimalSeparator,
			};

			countUpInstances.push( new CountUp( element, endNumber, options ) );
		} );
	}) ();


	/*** Lightbox et popups avec GLightbox ***************************************************************/
	// Requiert GLightbox (glightbox)
	// Requiert functions.initLightboxes

	(function() {
		functions.initLightboxes();
	}) ();

	/*** Selects de qui redirigent vers des URLs (notamment taxonomy-menu en mobile) ************************/
	$(document).on( 'change', '.link-select', function() {
		var selectedOption = $(this).find('option:selected');
		var targetUrl;

		if( 0 < selectedOption.length ) {
			targetUrl = selectedOption.attr('data-url');

			if( targetUrl ) {
				window.location.href = targetUrl;
			}
		}
	} );

	/*** Recherche qui s'ouvre/ferme en cliquant sur la loupe *********************************************/
	$('.search-form .search-button').click(function(event) {
		var theButton = $(this);
		var theWrapper = theButton.closest('.search-form');
		var theForm = theButton.closest('form');
		var theField = theForm.find('.search-field');

		if( ! theWrapper.hasClass('animating') ) {
			if( ! theWrapper.hasClass('open') ) {
				event.preventDefault();
				theWrapper.addClass('animating');
				theWrapper.addClass('open');

				setTimeout( function() {
					theField.focus();
				}, 400 );
			} else if( ! theField.val() ) {
				event.preventDefault();
				theWrapper.addClass('animating');
				theWrapper.removeClass('open');
			}

			setTimeout(function() {
				theWrapper.removeClass('animating');
			}, 400)
		} else {
			event.preventDefault();
		}
	});

	/*** Ajouter la classe loaded au background du hero de l'accueil *********************************************/
	(function() {
		let homeHeaderBg = document.querySelector('.home-hero-inner');

		if( 'undefined' != typeof homeHeaderBg && null != homeHeaderBg ) {
			homeHeaderBg.classList.add('loaded');
		}
	}) ();

	/*** Gestion des préférence de cookies ***************************************/
	(function() {
		window.acceptedCookies = cookiesBar.getCookie('acceptedCookies');

		if( 'function' == typeof cookiesBar.cookieConsent ) {
			cookiesBar.cookieConsent();
		}
	}) ();

}) ();