import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';
import { tns } from 'tiny-slider/src/tiny-slider.module.js';
import Glide from '@glidejs/glide/dist/glide.esm.js';
import GLightbox from 'glightbox/dist/js/glightbox.js';
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"


// Prend un sélecteur, incluant #hash, et renvoie une node HTMLElement (ou alors renvoie ce qu'on a reçu si c'en est déjà un)

export function selToElement( selector ) {
	let element;

	if( selector ) {
		if( 'string' == typeof selector ) {
			element = document.querySelector( ( selector.includes('#') ? '' : '#' ) + selector );
		} else {
			element = selector;
		}
	} else {
		element = null;
	}

	return element;
}


function getCurOffset( args ) {
	let curOffset = {
		top: 0,
		left: 0,
	};

	if( ! args || ! args.target ) {
		args = { target: args };
	}

	args.target = selToElement( args.target );

	if( args.target ) {
		let targetRect = args.target.getBoundingClientRect();

		args.offsetX = args.offsetX || 0;
		args.offsetY = args.offsetY || 0;

		args.parent = args.parent || window;

		if( parent == window ) {
			curOffset.top = targetRect.top + window.curScroll.top - window.fixedElHeight + args.offsetY;
			curOffset.left = targetRect.left + window.curScroll.left - window.fixedElWidth + args.offsetX;
		} else {
			curOffset.top = args.target.offsetTop + args.offsetY;
			curOffset.left = args.target.offsetLeft + args.offsetX;
		}

		curOffset.bottom = curOffset.top + targetRect.height;
		curOffset.right = curOffset.left + targetRect.width;
	} else {
		curOffset = null;
	}

	return curOffset;
}

export function getElementOffset(el) {
  let top = 0;
  let left = 0;
  let element = el;

  // Loop through the DOM tree
  // and add it's parent's offset to get page offset
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
}

export function smoothScrollTo( args ) {
	if( ! args || ! args.target ) {
		args = { target: args };
	}
	args.target = selToElement( args.target );

	if( args.target ) {
		args.alignX = args.alignX || 'left';
		args.alignY = args.alignY || 'top';
		args.scrollToOptions = args.scrollToOptions || {};
		args.parent = args.parent || window;
		args.setHash = 'undefined' != typeof args.setHash ? args.setHash : true;
		args.axis = args.axis || 'vertical';
		args.ignoreRange = args.ignoreRange || 10;

		let parentCurOffset;
		let targetCurOffset = getCurOffset({
			parent: args.parent,
			target: args.target,
		});

		if( window == args.parent ) {
			parentCurOffset = window.curScroll;
		} else {
			parentCurOffset = getCurOffset({
				parent: window,
				target: args.parent,
			});
		}

		let farEnoughCondition = (
			(
				targetCurOffset.top > parentCurOffset.top + args.ignoreRange
				|| targetCurOffset.top < parentCurOffset.top - args.ignoreRange
			)
			|| (
				targetCurOffset.left > parentCurOffset.left + args.ignoreRange
				|| targetCurOffset.left < parentCurOffset.left - args.ignoreRange
			)
		);

		// Est-ce qu'on s'apprête à scroller vers le haut ou vers le bas ? Ça va changer la hauteur de fixedElHeight à cause du menu slide-up.

		if( farEnoughCondition ) {
			if( targetCurOffset.top > parentCurOffset.top ) {
				window.scrollDirection = 'down';
				document.body.classList.add('scrolling-down');
				document.body.classList.remove('scrolling-up');
			} else {
				window.scrollDirection = 'up';
				document.body.classList.add('scrolling-up');
				document.body.classList.remove('scrolling-down');
			}

			calcFixedEls();
		}

		if( window == args.parent ) {
			args.offsetX = 'undefined' == typeof args.offsetX ? -1 * window.fixedElWidth : args.offsetX;
			args.offsetY = 'undefined' == typeof args.offsetY ? -1 * window.fixedElHeight : args.offsetY;
		} else {
			args.offsetX = 'undefined' == typeof args.offsetX ? 0 : args.offsetX;
			args.offsetY = 'undefined' == typeof args.offsetY ? 0 : args.offsetY;
		}

		// Valeurs par défaut de scrollIntoView
		args.scrollToOptions = { ...{
			behavior: 'smooth',
		}, ...args.scrollToOptions };

		let startEvent = new Event( 'smoothscrollstart' );
		let endEvent = new Event( 'smoothscrollend' );
		let scrollTimeout;
		// let scrollAgainTimeout;
		let origAutoHash = [];

		let targetDirParent = args.target.parentNode;
		// On ne scroll pas si on y est déjà presque anyway

		if( farEnoughCondition ) {
			let tmpTarget;
			let targetRect = args.target.getBoundingClientRect();
			let targetDirParentComStyle = window.getComputedStyle( targetDirParent );
			let parentPositionAltered = false;
			let tmpTargetRect;

			// Désactivation temporaire de l'auto-hash du site
			if( window == args.parent && 'undefined' !== typeof window.pageMenus ) {
				window.pageMenus.forEach( function( thisMenu ) {
					origAutoHash.push( thisMenu.autoHash );
					thisMenu.autoHash = false;
				} );
			}

			// Position relative temporaire du parent si nécessaire (pour l'élément temporaire)

			if( 'static' == targetDirParentComStyle.getPropertyValue( 'position' ) ) {
				targetDirParent.style.position = 'relative';
				parentPositionAltered = true;
			}

			// Création d'un élément temporaire qu'on va tasser de l'offset.

			tmpTarget = document.createElement('div');
			tmpTarget.classList.add( 'smooth-scroll-tmp-target' );

			Object.assign( tmpTarget.style, {
				height: targetRect.height + 'px',
				width: targetRect.width + 'px',

				position: 'absolute',
				top: ( args.target.offsetTop + args.offsetY ) + 'px',
				left: ( args.target.offsetLeft + args.offsetX ) + 'px',

				// pointerEvents: 'none',
				visibility: 'hidden',
			} );

			targetDirParent.appendChild( tmpTarget );
			tmpTargetRect = tmpTarget.getBoundingClientRect();


			// Calcul de la destination du scroll

			if( 'undefined' == typeof args.scrollToOptions.top ) {
				if( window == args.parent ) {
					if( window.vWidth <= 920 ) {
						args.scrollToOptions.top = tmpTargetRect.top + parentCurOffset.top - 59;
					} else {
						args.scrollToOptions.top = tmpTargetRect.top + parentCurOffset.top;
					}

					if( 'center' == args.alignY ) {
						args.scrollToOptions.top = args.scrollToOptions.top - ( window.vHeight / 2 ) + ( tmpTargetRect.height / 2 );
					} else if( 'bottom' == args.alignY ) {
						args.scrollToOptions.top = args.scrollToOptions.top + ( window.vHeight / 2 ) - tmpTargetRect.height;
					}
				} else {
					args.scrollToOptions.top = tmpTarget.offsetTop;
				}
			}

			if( 'undefined' == typeof args.scrollToOptions.left ) {
				if( window == args.parent ) {
					args.scrollToOptions.left = tmpTargetRect.left + parentCurOffset.left;

					if( 'center' == args.alignX ) {
						args.scrollToOptions.left = args.scrollToOptions.left - ( window.vWidth / 2 ) + ( tmpTargetRect.width / 2 );
					} else if( 'right' == args.alignX ) {
						args.scrollToOptions.left = args.scrollToOptions.left + ( window.vWidth / 2 ) - tmpTargetRect.width;
					}
				} else {
					args.scrollToOptions.left = tmpTarget.offsetLeft;
				}
			}


			// Évènement à la fin du scroll

			args.parent.addEventListener( 'scroll', function scrollListener( event ) {
				if( scrollTimeout ) {
					clearTimeout( scrollTimeout );
				}

				// if( scrollAgainTimeout ) {
				// 	clearTimeout( scrollAgainTimeout );
				// }

				// On recalcule la destination à la fin, au cas où un lazy load allongerait la page.

				/*
				scrollAgainTimeout = setTimeout( function() {
					targetCurOffset = getCurOffset({
						parent: args.parent,
						target: args.target,
						offsetX: ( 'horizontal' == args.axis ? args.offset : 0 ),
						offsetY: ( 'vertical' == args.axis ? args.offset : 0 ),
					});

					farEnoughCondition = (
						(
							targetCurOffset.top > parentCurOffset.top + args.ignoreRange
							|| targetCurOffset.top < parentCurOffset.top - args.ignoreRange
						)
						|| (
							targetCurOffset.left > parentCurOffset.left + args.ignoreRange
							|| targetCurOffset.left < parentCurOffset.left - args.ignoreRange
						)
					);

					if( farEnoughCondition ) {
						args.target.scrollIntoView( args.scrollToOptions );
					}
				}, 40 );
				*/

				scrollTimeout = setTimeout( function() {
					let targetId = args.target.getAttribute('id');

					if( targetId && args.setHash ) {
						changeHashWithoutScrolling( targetId );
					}

					// On débloque l'auto-hash qu'on a bloqué en commençant le scroll
					if( window == args.parent && 'undefined' !== typeof window.pageMenus ) {
						window.pageMenus.forEach( function( thisMenu, index ) {
							thisMenu.autoHash = origAutoHash[index];
						} );
					}

					// Suppression de la cible temporaire

					tmpTarget.remove();

					if( parentPositionAltered ) {
						targetDirParent.style.removeProperty( 'position' );
					}

					args.parent.dispatchEvent( endEvent );
					args.parent.removeEventListener( 'scroll', scrollListener );
				}, 50 );
			} );

			//Le scroll

			args.parent.dispatchEvent( startEvent );
			args.parent.scrollTo( args.scrollToOptions );
		}
	}
}


export function initSliderTiny( slider, extraOptions ) {
	let lazySelector = '.tns-lazy-img';
	let autoplayButtonSelector = '.tns-autoplay-button';

	if( 'string' == typeof slider ) {
		slider = document.querySelector( slider );
	}
	let sliderOptions = {};
	let sliderObject;
	let maxItems = parseFloat( slider.dataset.maxItems || 1 );
	let minItems = parseFloat( slider.dataset.minItems || 1 );
	let maxWidth = parseFloat( slider.dataset.maxWidth || Infinity );
	let minWidth = parseFloat( slider.dataset.minWidth || 0 );
	let nbItems = slider.querySelectorAll('.slider-element').length;
	
	extraOptions = extraOptions || {};

	if( nbItems > 1 && window.vWidth > minWidth && window.vWidth < maxWidth ) {
		sliderOptions = {
			container: slider,
			mode: slider.classList.contains( 'fade' ) ? 'gallery' : 'carousel',
			axis: slider.classList.contains( 'vertical' ) ? 'vertical' : 'horizontal',
			autoplay: slider.classList.contains( 'autoplay' ),
			items: minItems, // les settings responsive grandissent ce nombre
			gutter: slider.classList.contains( 'gutter' ) ? 20 : 0, // les settings responsive grandissent ce nombre
			edgePadding: slider.classList.contains( 'edge-padding' ) ? 20 : 0,
			fixedWidth: false,
			autoWidth: false,
			viewportMax: false,
			slideBy: 1,
			center: false,
			controls: slider.classList.contains( 'controls' ),
			controlsPosition: slider.classList.contains( 'controls-top' ) ? 'top' : 'bottom',
			controlsText: [
				'<span class="button-inner"><span class="icon icon-chevron-thin-left" aria-label="' + bonesGlobals.i18n.tinySlider.prev + '" title="' + bonesGlobals.i18n.tinySlider.prev + '"></span></span>',
				'<span class="button-inner"><span class="icon icon-chevron-thin-right" aria-label="' + bonesGlobals.i18n.tinySlider.next + '" title="' + bonesGlobals.i18n.tinySlider.next + '"></span></span>',
			],
			// va falloir étudier comment ça marche pour les boutons custom
			controlsContainer: slider.dataset.navContainer,
			nav: slider.classList.contains( 'nav' ),
			navPosition: slider.classList.contains( 'nav-top' ) ? 'top' : 'bottom',
			navContainer: slider.dataset.navContainer,
			navAsThumbnails: slider.classList.contains( 'nav-thumbs' ),
			arrowKeys: slider.classList.contains( 'arrow-keys' ),
			speed: slider.dataset.speed || 300,
			autoplay: slider.classList.contains( 'autoplay' ),
			autoplayPosition: 'top',
			autoplayTimeout: slider.dataset.autoplayTimeout || 5000,
			autoplayDirection: slider.classList.contains( 'autoplay-backward' ) ? 'backward' : 'forward',
			autoplayText: [
				bonesGlobals.i18n.tinySlider.autoplay.start,
				bonesGlobals.i18n.tinySlider.autoplay.stop,
			],
			autoplayHoverPause: slider.classList.contains( 'autoplay-hover-pause'),
			autoplayButton: slider.querySelector( autoplayButtonSelector ) || false,
			autoplayButtonOutput: true,
			autoplayResetOnVisibility: true,
			loop: slider.classList.contains( 'loop' ),
			rewind: slider.classList.contains( 'rewind' ),
			autoHeight: slider.classList.contains( 'auto-height' ),
			lazyload: slider.querySelectorAll( lazySelector ).length > 0,
			lazyloadSelector: lazySelector,
			touch: slider.classList.contains( 'touch' ),
			mouseDrag: slider.classList.contains( 'drag' ),
			preventScrollOnTouch: slider.classList.contains( 'prevent-scroll' ),

			// Voir plus bas pour le responsive
			responsive: {}
			// onInit: function() {}
		};

		// Placeholders pour pouvoir mettre des valeurs responsive plus bas

		sliderOptions.responsive['500'] = {};
		sliderOptions.responsive['700'] = {};
		sliderOptions.responsive['1000'] = {};
		sliderOptions.responsive['1200'] = {};
		sliderOptions.responsive['1400'] = {};
		sliderOptions.responsive['1600'] = {};


		// Options responsive

		if( slider.classList.contains( 'edge-padding' ) ) {
			sliderOptions.responsive['500'].edgePadding = 40;
			sliderOptions.responsive['700'].edgePadding = 50;
			sliderOptions.responsive['1000'].edgePadding = 80;
			sliderOptions.responsive['1400'].edgePadding = 120;
			sliderOptions.responsive['1600'].edgePadding = 0;
		}

		if( slider.classList.contains( 'gutter' ) ) {
			sliderOptions.responsive['500'].gutter = 30;
		}

		if( maxItems >= 2 ) {
			sliderOptions.responsive['700'].singleItem = false;
			sliderOptions.responsive['700'].items = 2;

			if( maxItems >= 3 ) {
				sliderOptions.responsive['1200'].items = 3;
			}

			if( maxItems >= 4 ) {
				sliderOptions.responsive['1600'].items = 4;
			}
		}

		// Options custom qui écrasent tout le reste

		if( Object.keys( extraOptions ).length > 0 ) {
			for( let optionName in extraOptions ) {
				sliderOptions[optionName] = extraOptions[optionName];
			}
		}

		// Initialisation

		// console.log( sliderOptions );
		sliderObject = tns( sliderOptions );
		slider.classList.add('slider-initiated');
	} else {
		slider.querySelectorAll( lazySelector ).forEach( function( thisEl ) {
			thisEl.classList.add('lazy');
		} );
	}
}


export function initSliderOwl( slider, extraOptions ) {
	slider = $(slider);
	
	let lazyLoad = slider.find('.owl-lazy').length > 0;
	let carouselOptions = {};
	let sliderObject;
	let maxItems = slider.attr('data-max-items') ? slider.attr('data-max-items') : 1;
	let nbItems = slider.find('.slider-element').length;

	extraOptions = extraOptions || {};

	if( nbItems > 1 ) {
		slider.addClass('owl-carousel').addClass('owl-theme');
		carouselOptions = {
			autoplay: slider.hasClass( 'autoplay' ),
			// autoplay: false,
			loop: ! slider.hasClass( 'no-loop' ),
			autoplayTimeout: 10000,
			singleItem: maxItems <= 1,
			nav: slider.hasClass( 'nav' ),
			dots: slider.hasClass( 'dots' ),
			touchDrag: slider.hasClass( 'drag' ),
			mouseDrag: slider.hasClass( 'drag' ),
			lazyLoad: lazyLoad,
			stagePadding : slider.hasClass( 'stage-padding' ) ? 40 : 0,
			items: 1,
			smartSpeed: 600,
			navText: [
				'<span class="icon icon-carousel-arrow-left" aria-label="' + bonesGlobals.i18n.owlCarousel.prev + '" title="' + bonesGlobals.i18n.owlCarousel.prev + '"></span>',
				'<span class="icon icon-carousel-arrow-right" aria-label="' + bonesGlobals.i18n.owlCarousel.next + '" title="' + bonesGlobals.i18n.owlCarousel.next + '"></span>',
			],
			animateOut: slider.hasClass( 'fade' ) ? 'fadeOut' : false,
			responsive: {},
		};

		if( slider.hasClass( 'stage-padding' ) ) {
			carouselOptions.responsive['500'] = { stagePadding: 80 };
		}

		if( maxItems > 1 ) {
			carouselOptions.margin = 20;
			carouselOptions.responsive['700'] = {
				singleItem: false,
				items: 2,
			};

			if( maxItems >= 3 ) {
				carouselOptions.responsive['1000'] = {
					singleItem: false,
					items: 3,
				};
			}

			if( maxItems >= 4 ) {
				carouselOptions.responsive['1200'] = {
					singleItem: false,
					items: 4,
				};
			}

			if( maxItems >= 5 ) {
				carouselOptions.responsive['1600'] = {
					singleItem: false,
					items: 5,
				};
			}
		}

		// Thumbs
		if( slider.hasClass( 'thumbs' ) ) {
			carouselOptions.onInitialized = function(event) {
				let dotsWrapper = slider.find('.owl-dots');
				let dots = dotsWrapper.children();
				let curEl = 0;

				dotsWrapper.addClass('owl-thumbs');

				slider.find('.owl-item:not(.cloned)').each(function() {
					let imgToUse = $(this).find('.slider-element').attr('data-thumb-src');

					dots.eq( curEl ).addClass('owl-thumb').append('<img class="lazy owl-thumb-image" data-src="' + imgToUse + '" />');

					curEl += 1;
				});
			};
		}


		if( Object.keys( extraOptions ).length > 0 ) {
			for( let optionName in extraOptions ) {
				carouselOptions[optionName] = extraOptions[optionName];
			}
		}

		sliderObject = slider.owlCarousel( carouselOptions );

		if( slider.hasClass('autoplay') ) {
			sliderObject.on('changed.owl.carousel', function(e) {
				sliderObject.trigger('stop.owl.autoplay');
				sliderObject.trigger('play.owl.autoplay');
			});
		}

		slider.find('.owl-next-custom').click(function() {
			sliderObject.trigger('next.owl.carousel');
		});

		slider.find('.owl-prev-custom').click(function() {
			sliderObject.trigger('prev.owl.carousel');
		});
	} else {
		slider.find('.owl-lazy')
			.removeClass('owl-lazy')
			.addClass('lazy')
		;
	}
}


// elem : Élément DOM sur lequel on se pose la question
// offset : Nombre de pixels de grâce (nombre plus gros = plus tolérant)
// completelyInside : si true, l'élément doit être à 100% dans le viewport ; si false, il peut dépasser
// parent : par rapport à quoi? | défaut window/viewport

export function isInViewport( elem, offset, completelyInside, parent ) {
	offset = offset || ( -1 * window.fixedElHeight );
	completelyInside = completelyInside || false;
	parent = parent || window;

	let condition;
	let viewportBottom;
	let viewportTop;
	let elemTop;
	let elemBottom;

	if( 'string' == typeof elem ) {
		elem = document.querySelector( elem );
	}

	if( elem ) {
		if( parent == window ) {
			viewportTop = 0;
			viewportBottom = window.vHeight;
			elemTop = elem.getBoundingClientRect().top;
			elemBottom = elem.getBoundingClientRect().bottom;
		} else {
			viewportTop = parent.scrollTop;
			viewportBottom = parent.scrollTop + parent.offsetHeight;
			elemTop = elem.offsetTop;
			elemBottom = elem.offsetBottom + elem.offsetHeight;
		}


		if( completelyInside ) {
			condition = (
				( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
				&& ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
			);
		} else {
			condition = (
				( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
				|| ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
			);
		}
	} else {
		condition = null;
	}

	return condition;
}


export function removeHash() {
    history.pushState(
    	"",
    	document.title,
    	window.location.pathname + window.location.search
    );
}


export function changeHashWithoutScrolling( hash ) {
	if( hash ) {
		hash = ( hash.includes('#') ? '' : '#' ) + hash;
	}

	if( history.pushState ) {
		if( hash ) {
			history.pushState( null, null, hash );
		} else {
			//URL sans hash
			history.pushState( null, null, window.location.href.replace( window.location.hash, '' ) );
		}
	} else {
		if( hash ) {
			window.location.hash = hash;
		} else {
			removeHash();
		}
	}
}


export function smoothFadeIn( thisEl ) {
	if( ! thisEl.classList.contains( 'visible' ) && isInViewport( thisEl ) ) {
		thisEl.classList.add( 'visible' );
	}
}

export function changeUrl( args ) {
	let returnVal;

	switch(args.context) {
		case 'open':
			window.changeUrlOriginalTitle = document.title;
			window.changeUrlOriginalUrl = window.location.href;
			window.changeUrlOriginalId = document.body.dataset.id || window.location.href;

			window.history.pushState(
				{
					id: 'popup-' + args.id
				},
				args.title,
				args.url
			);
			document.title = args.title;

			returnVal = true;
			break;

		case 'close':
			if( window.changeUrlOriginalId ) {
				window.history.pushState(
					{
						id: window.changeUrlOriginalId
					},
					window.changeUrlOriginalTitle,
					window.changeUrlOriginalUrl
				);
				document.title = changeUrlOriginalTitle;

				window.changeUrlOriginalTitle = null;
				window.changeUrlOriginalUrl = null;
				window.changeUrlOriginalId = null;

				returnVal = true;
				break;
			}
	}

	return returnVal;
}

export function loadingStart( parent ) {
	parent = parent || document.body;

	let loadingEl = parent.querySelector('.nubee-loading');

	if( ! loadingEl ) {
		let preloaderEl;
		let subLoadingEl;
		let labelEl;

		loadingEl = document.createElement( 'div' );
		loadingEl.classList.add( 'nubee-loading' );
		loadingEl.classList.add( 'admin-bar-offset' );
		subLoadingEl = document.createElement( 'div' );
		subLoadingEl.classList.add( 'nubee-loading-inner' );
		preloaderEl = document.querySelector('.footer-hidden-elements .preloader-facebook').cloneNode( true );
		preloaderEl.classList.add( 'nubee-loading-preloader' );
		labelEl = document.createElement( 'div' );
		labelEl.classList.add( 'nubee-loading-preloader' );
		labelEl.textContent = window.bonesGlobals.i18n.functions.loading;

		subLoadingEl.append( preloaderEl, labelEl );
		loadingEl.append( subLoadingEl );
		parent.append( loadingEl );
	}

	// On va chercher une valeur calculée random pour forcer le navigateur à faire la transition
	// https://stackoverflow.com/a/24195559/1995518
	loadingEl.offsetWidth;

	disableBodyScroll( loadingEl );
	document.documentElement.classList.add( 'nubee-loading-visible' );

	loadingEl.classList.add( 'visible' );
}

export function loadingEnd( parent ) {
	parent = parent || document.body;
	let loadingEl = parent.querySelector('.nubee-loading');

	enableBodyScroll( loadingEl );
	document.documentElement.classList.remove( 'nubee-loading-visible' );

	if( loadingEl ) {
		loadingEl.classList.remove( 'visible' );
	}
}

// https://stackoverflow.com/a/9310752/1995518
export function escapeRegExp(text) {
	if ( ! text || 'string' != typeof text ) {
		return '';
	}
	return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
}

export function arrayAllEqual( array ) {
	return array.every( function( elementToCheck ) {
		return elementToCheck === array[0];
	} );
}

export function urlsAreSamePage( args ) {
	let getParamsRegex = /\?.+$/;
	let trailingSlashRegexes = [
		{
			regex: /\/$/,
			replacement: '',
		},
		{
			regex: /\/\?/,
			replacement: '?',
		},
		{
			regex: /\/#/,
			replacement: '#',
		}
	];
	let nakedUrls = [];
	args = args || {};

	args.urls = args.urls || [];
	args.stripHash = args.stripHash || true;
	args.stripGet = args.stripGet || false;

	if( 'string' == typeof args.urls ) {
		args.urls = [ args.urls ];
	}

	// Une seule URL ? On compare avec celle de la page actuelle.
	if( Array.isArray( args.urls ) && 1 == args.urls.length ) {
		args.urls.push( window.location.href );
	}

	args.urls.forEach( function( thisUrl ) {
		let hashIndex = thisUrl.indexOf( '#' );
		let urlHash = -1 !== hashIndex ? thisUrl.substring( hashIndex ) : '';
		let nakedUrl = thisUrl;

		if( args.stripHash ) {
			nakedUrl = nakedUrl.replace( urlHash, '' );
		}

		if( args.stripGet ) {
			nakedUrl = nakedUrl.replace( getParamsRegex, '' );
		}

		trailingSlashRegexes.forEach( function( thisRegex ) {
			nakedUrl.replace( thisRegex.regex, thisRegex.replacement );
		} );

		nakedUrls.push( nakedUrl );
	} );

	return arrayAllEqual( nakedUrls );
}



/*** Combien de chiffres après la virgule le chiffre a-t-il ? *********************************/
// https://stackoverflow.com/a/27082406/1995518

export function countDecimals( value ) {
	if( Math.floor(value) !== value ) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
}


export function calcFixedEls() {
	let pageMenuEl = document.querySelector('.page-menu-block');
	let adminBarEl = document.querySelector('#wpadminbar');
	let scrollUpMenu = document.querySelector('.docked-menu-container');
	let headerDesktop = document.querySelector('.site-header');
	let headerMobile = document.querySelector('.site-header-mobile') || headerDesktop;

	window.fixedElWidth = 0;
	window.fixedElHeight = pageMenuEl ? pageMenuEl.clientHeight : 0;
	window.fixedElHeight += adminBarEl ? adminBarEl.clientHeight : 0;

	if( 920 >= window.vWidth ) {
		window.headerHeight = headerMobile.offsetHeight;
	} else {
		window.headerHeight = headerDesktop.offsetHeight;

		// Si on est en train de scroll vers le haut, on compte le menu scroll-up
		if( 'up' == window.scrollDirection && document.body.classList.contains( 'scrolled-a-lot' ) ) {
			window.fixedElHeight += scrollUpMenu ? scrollUpMenu.clientHeight : 0;
		}
	}
}

export function animateSVGElement( thisEl, action ) {
	thisEl.querySelectorAll('.tile-animation-' + action).forEach( function( animateEl ) {
		animateEl.beginElement();
	});
}


function buildLightboxElement( htmlEl ) {
	let elHref = htmlEl.href || null;
	let elSource = null;
	let elType = null;
	let newElement = null;

	if( elHref ) {
		newElement = {};

		if( elHref.match(/\.(jpg|jpeg|gif|png|svg|webp|tif|tiff|bmp|apng|ico|cur|jfif|pjpeg|pjp)$/i) ) {
			elType = 'image';
		} else if( elHref.match(/\.(mp4|ogg|mov|webm|avi|mkv|ogv)$/i) ) {
			elType = 'video';
			elSource = 'local';
		} else if( elHref.match(/youtu/i) ) {
			elType = 'video';
			elSource = 'youtube'
		} else if( elHref.match(/vimeo/i) ) {
			elType = 'video';
			elSource = 'vimeo'
		} else if( elHref.match(/^#/i) ) {
			elType = 'inline';
		} else {
			elType = 'external';
		}

		newElement.href = elHref;
		newElement.type = elType;

		if( elSource ) {
			newElement.source = elSource;
		}
	}

	return newElement;
}

export function initLightboxes() {
	// Lightbox simples
	(function() {
		let lightboxEls = document.querySelectorAll('.lightbox');

		lightboxEls.forEach( function( thisEl ) {
			let lightboxInstance;
			let newElement = buildLightboxElement( thisEl );

			if( newElement ) {
				let popupOptions = {
					elements: [ newElement ],
					autoplayVideos: true,
					keyboardNavigation: true,
					skin: 'clean nubee nubee-single',
				};

				thisEl.addEventListener( 'click', function( event ) {
					event.preventDefault();
					lightboxInstance.open();
				} );

				lightboxInstance = GLightbox( popupOptions );
				// console.log( lightboxInstance );
			}
		} );
	}) ();

	// Lightbox en galeries
	(function() {
		let lightboxGals = document.querySelectorAll('.lightbox-gallery');

		lightboxGals.forEach( function( thisGal ) {
			let lightboxEls = thisGal.querySelectorAll('.lightbox-gallery-el');

			let popupOptions = {
				elements: [],
				autoplayVideos: true,
				keyboardNavigation: true,
				skin: 'clean nubee nubee-gallery',
				startAt: 0,
			};

			let lightboxInstance;
			let galIndex = 0;

			lightboxEls.forEach( function( thisEl ) {
				let newElement = buildLightboxElement( thisEl );

				if( newElement ) {
					thisEl.setAttribute( 'data-lightbox-gallery-index', galIndex );

					thisEl.addEventListener( 'click', function( event ) {
						let clickedIndex = event.target.dataset.lightboxGalleryIndex;
						event.preventDefault();
						// lightboxInstance.settings.startAt = clickedIndex;
						lightboxInstance.open();
					} );

					popupOptions.elements.push( newElement );

					++ galIndex;
				}
			} );

			if( popupOptions.elements.length ) {
				lightboxInstance = GLightbox( popupOptions );
				// console.log( lightboxInstance );
			}
		} );
	}) ();
}

// Pour boutons +/- sur les input de type number

export function initNumberInput( thisInput ) {
	// On monte jusqu'à 5 niveaux dans le DOM à la recherche de boutons +/-
	let levelsClimbed = 0;
	let decreaseButton;
	let increaseButton;
	let curParent = thisInput;

	while( curParent && levelsClimbed < 6 && ! decreaseButton && ! increaseButton ) {
		curParent = curParent.parentElement;

		if( ! decreaseButton ) {
			decreaseButton = curParent.querySelector('.input-number-decrease');
		}

		if( ! increaseButton ) {
			increaseButton = curParent.querySelector('.input-number-increase');
		}

		++ levelsClimbed;
	}

	if( decreaseButton ) {
		decreaseButton.addEventListener( 'click', function( event ) {
			let changeEvent = new Event('change');
			let inputEvent = new Event('input');

			event.preventDefault();

			if( thisInput.step ) {
				thisInput.stepDown();
			} else {
				-- thisInput.value;
			}

			thisInput.dispatchEvent( changeEvent );
			thisInput.dispatchEvent( inputEvent );
		} );
	}

	if( increaseButton ) {
		increaseButton.addEventListener( 'click', function( event ) {
			let changeEvent = new Event('change');
			let inputEvent = new Event('input');

			event.preventDefault();

			if( thisInput.step ) {
				thisInput.stepUp();
			} else {
				++ thisInput.value;
			}

			thisInput.dispatchEvent( changeEvent );
			thisInput.dispatchEvent( inputEvent );
		} );
	}
}

export function bookingCountChildren( nbField, childFields ) {
	let value = parseFloat( nbField.value );

	childFields.forEach( function( thisField ) {
		thisField.value = '';
	} );

	if( 1 <= value ) {
		childFields[0].value = 1;
	}

	if( 2 <= value ) {
		childFields[1].value = 1;
	}

	if( 3 <= value ) {
		childFields[2].value = 1;
	}

	if( 4 <= value ) {
		childFields[3].value = 1;
	}
}

export function initFlatpickr( thisField ) {
	let pickerOptions = {
		altInput: true,
		altFormat: 'j F',
		dateFormat: 'Y-m-d',
		locale: window.bonesGlobals.languageCode,
	};

	// Date par défaut. Attribut existant mais vide = champ vide par défaut. Pas d'attribut = défaut aujourd'hui.
	if( 'undefined' != typeof thisField.dataset.defaultDate ) {
		if( thisField.dataset.defaultDate ) {
			pickerOptions.defaultDate = Date.parse( thisField.dataset.defaultDate );
		}
	} else {
		pickerOptions.defaultDate = new Date();
	}

	// Date minimum. Attribut existant mais vide = pas de minimum. Pas d'attribut = minimum aujourd'hui.
	if( 'undefined' != typeof thisField.dataset.minDate ) {
		if( thisField.dataset.minDate ) {
			pickerOptions.minDate = Date.parse( thisField.dataset.minDate );
		}
	} else {
		pickerOptions.minDate = new Date();
	}

	// Date maximum. Attribut existant mais vide = pas de maximum. Pas d'attribut = pas de maximum.
	if( 'undefined' != typeof thisField.dataset.maxDate ) {
		if( thisField.dataset.maxDate ) {
			pickerOptions.maxDate = Date.parse( thisField.dataset.maxDate );
		}
	}

	// La date choisie dans ce champ influence le minimum d'un autre champ
	if( thisField.dataset.setsMinOf ) {
		let minTarget = document.querySelector( thisField.dataset.setsMinOf );

		pickerOptions.onChange = function( selectedDates, dateStr, instance ) {
			let minTargetInstance = minTarget._flatpickr;

			if( minTargetInstance && selectedDates.length ) {
				minTargetInstance.set( 'minDate', selectedDates[0] );
			}
		};
	}

	// La date choisie dans ce champ influence le maximum d'un autre champ
	if( thisField.dataset.setsMaxOf ) {
		let maxTarget = document.querySelector( thisField.dataset.setsMaxOf );

		pickerOptions.onChange = function( selectedDates, dateStr, instance ) {
			let maxTargetInstance = maxTarget._flatpickr;

			if( maxTargetInstance && selectedDates.length ) {
				maxTargetInstance.set( 'maxDate', selectedDates[0] );
			}
		};
	}

	let pickerInstance = flatpickr( thisField, pickerOptions );
}
