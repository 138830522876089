// Requiert functions.isInViewport
// Requiert functions.smoothScrollTo

import * as functions from 'functions.js';

function FormValidation( args ) {
	let that = this;
	args = args || {};

	args.submitSel = args.submitSel || 'button[type="submit"], input[type="submit"]';
	args.wrapFieldSel = args.wrapFieldSel || '.wrap-field';
	args.fieldOuterSel = args.fieldOuterSel || '.form-row';
	args.classInvalidTmp = args.classInvalidTmp || 'tmp-invalid';
	args.classHiddenTmp = args.classHiddenTmp || 'tmp-hidden';
	args.classVisible = args.classVisible || 'visible';
	args.classPrefix = args.classPrefix || 'validation-';
	args.classMessage = args.classMessage || 'message';
	args.classIcon = args.classIcon || 'icon';
	args.theIconPrefix = args.theIconPrefix || 'icon-';
	args.messageTagName = args.messageTagName || 'aside';
	args.eventDelay = args.eventDelay || 600;

	this.args = args;

	this.init = function() {
		that.i18n = window.bonesGlobals.i18n.validation;
		that.form = that.args.form;
		that.submitButtons = that.form.querySelectorAll( that.args.submitSel );

		// On désactive la validation normale pour que l'utilisateur ait le droit d'essayer de soumettre (et triggerer notre validation)
		that.form.noValidate = true;

		// On enregistre dans chaque champ ses éléments HTML importants et ses classes d'origine
		for( let thisEl of that.form.elements ) {
			thisEl.wrapField = thisEl.closest( that.args.wrapFieldSel );
			thisEl.fieldOuter = thisEl.closest( that.args.fieldOuterSel );
			thisEl.fieldOuter.originalClasses = thisEl.fieldOuter.className;

			if( thisEl.wrapField ) {
				thisEl.message = that.createFieldMessage();
				thisEl.icon = that.createFieldIcon();
				thisEl.wrapField.append( thisEl.icon );
				thisEl.wrapField.append( thisEl.message );
			}
		}
	};

	this.updateClasses = function( whatToUpdate ) {
		whatToUpdate = whatToUpdate || that.form.elements;

		if( whatToUpdate instanceof HTMLCollection ) {
			for( let thisEl of whatToUpdate ) {
				that.updateClassesForEl( thisEl );
			}
		} else {
			that.updateClassesForEl( whatToUpdate );
		}
	};

	this.updateClassesForEl = function( thisEl ) {
		let newClasses = [];
		that.resetClassesForEl( thisEl );

		for( let propertyName in thisEl.validity ) {
			let classFinalPart;

			if( thisEl.validity[ propertyName ] ) {
				classFinalPart = 'true';
			} else {
				classFinalPart = 'false';
			}

			newClasses.push( that.args.classPrefix + propertyName + '-' + classFinalPart );
		}

		thisEl.fieldOuter.classList.add( ...newClasses );
	};

	this.resetClasses = function( whatToReset ) {
		whatToReset = whatToReset || that.form.elements;

		if( whatToReset instanceof HTMLCollection ) {
			for( let thisEl of whatToReset ) {
				that.resetClassesForEl( thisEl );
			}
		} else {
			that.resetClassesForEl( whatToReset );
		}
	}

	this.resetClassesForEl = function( thisEl ) {
		thisEl.fieldOuter.className = thisEl.fieldOuter.originalClasses;
	};

	this.updateMessages = function( whatToUpdate ) {
		let includeGlobal = false;

		if( ! whatToUpdate ) {
			whatToUpdate = that.form.elements;
			includeGlobal = true;
		}

		if( whatToUpdate instanceof HTMLCollection ) {
			for( let thisEl of whatToUpdate ) {
				that.updateMessageForEl( thisEl );
			}
		} else {
			that.updateMessageForEl( whatToUpdate );
		}

		if( includeGlobal ) {
			that.updateMessageGlobal();
		}
	};

	this.updateMessageForEl = function( thisEl ) {
		// console.log(thisEl.message);

		if( thisEl.message ) {
			let errorStrings = [];

			for( let messageType in thisEl.validity ) {

				if( thisEl.validity[ messageType ] ) {
					let thisErrorString = that.i18n[messageType] || null;


					if( thisErrorString ) {
						let insertString;

						switch( messageType ) {
							case 'typeMismatch':
								thisErrorString = that.i18n[messageType][thisEl.type] || that.i18n[messageType].default;
								break;
							case 'rangeOverflow':
								insertString = '<strong>' + thisEl.max + '</strong>';
								thisErrorString = thisErrorString.replace( '%f', insertString );
								break;
							case 'rangeUnderflow':
								insertString = '<strong>' + thisEl.min + '</strong>';
								thisErrorString = thisErrorString.replace( '%f', insertString );
								break;
							case 'stepMismatch':
								insertString = '<strong>' + thisEl.step + '</strong>';
								thisErrorString = thisErrorString.replace( '%f', insertString );
								break;
							case 'tooLong':
								insertString = '<strong>' + thisEl.maxLength + '</strong>';
								thisErrorString = thisErrorString.replace( '%f', insertString );
								break;
							case 'tooShort':
								insertString = '<strong>' + thisEl.minLength + '</strong>';
								thisErrorString = thisErrorString.replace( '%f', insertString );
								break;
							default:
								// Rien à remplacer pour les autres types
								break;
						}

						if( thisErrorString ) {
							errorStrings.push( thisErrorString );
						}
					}
				}
			}

			thisEl.icon.classList.add( that.args.classVisible );

			if( errorStrings.length > 0 ) {
				let messageList = thisEl.message.querySelector( '.' + that.args.classPrefix + that.args.classMessage + '-list' );
				let frag = new DocumentFragment();

				errorStrings.forEach( function( thisErrorString ) {
					let listEl = document.createElement( 'li' );
					listEl.innerHTML = thisErrorString;
					frag.append( listEl );
				} );

				if( thisEl.message.classList.contains( that.args.classVisible ) ) {
					// Si le message est déjà visible, on le cache le temps de changer les strings
					thisEl.message.classList.add( that.args.classHiddenTmp );
					thisEl.icon.classList.add( that.args.classHiddenTmp );

					thisEl.message.addEventListener( 'transitionend', function messageDisappeared( event ) {
						// Le message a fini de disparaître ! On peut changer la string puis lancer la réapparition.
						that.setMessageListHtml( messageList, frag );
						thisEl.message.classList.remove( that.args.classHiddenTmp );
						thisEl.icon.classList.remove( that.args.classHiddenTmp );
						thisEl.message.removeEventListener( 'transitionend', messageDisappeared );
					} );
				} else {
					that.setMessageListHtml( messageList, frag );
				}

				thisEl.message.classList.add( that.args.classVisible );
			} else {
				// Aucune erreur ! On cache la bulle.
				thisEl.message.classList.remove( that.args.classVisible );
			}
		}
	};

	this.setMessageListHtml = function( messageList, newHtml ) {
		while( messageList.lastChild ) {
			messageList.removeChild( messageList.lastChild );
		}
		messageList.append( newHtml );
	}

	this.updateMessageGlobal = function(  ) {

	};

	this.createFieldMessage = function() {
		let messageEl;
		let messageInnerEl;
		let messageListEl;

		messageEl = document.createElement( that.args.messageTagName );
		messageEl.classList.add( that.args.classPrefix + that.args.classMessage );
		messageEl.setAttribute( 'role', 'alert' );

		messageInnerEl = document.createElement( 'div' );
		messageInnerEl.classList.add( that.args.classPrefix + that.args.classMessage + '-inner' );

		messageListEl = document.createElement( 'ul' );
		messageListEl.classList.add( that.args.classPrefix + that.args.classMessage + '-list' );

		messageInnerEl.append( messageListEl );
		messageEl.append( messageInnerEl );
		return messageEl;
	};

	this.createFieldIcon = function() {
		let iconEl;
		let iconInnerEl;
		let theIconEl;

		iconEl = document.createElement( 'div' );
		iconEl.classList.add( that.args.classPrefix + that.args.classIcon );

		iconInnerEl = document.createElement( 'div' );
		iconInnerEl.classList.add( that.args.classPrefix + that.args.classIcon + '-inner' );

		theIconEl = document.createElement( 'div' );
		theIconEl.classList.add( that.args.classPrefix + that.args.classIcon + '-the-icon' );

		iconInnerEl.append( theIconEl );
		iconEl.append( iconInnerEl );
		return iconEl;
	};

	this.goToFirstInvalid = function() {
		let firstInvalid = that.getFirstInvalid();

		if( ! functions.isInViewport( firstInvalid ) ) {
			functions.smoothScrollTo({ target: firstInvalid });

			window.addEventListener( 'smoothscrollend', function() {
				firstInvalid.focus();
			} );
		} else {
			firstInvalid.focus();
		}
	};

	this.getFirstInvalid = function() {
		let firstInvalid = null;

		for( let thisEl of that.form.elements ) {
			if( ! thisEl.checkValidity() ) {
				firstInvalid = thisEl;
				break;
			}
		}

		return firstInvalid;
	}

	// Initialisation

	this.init();

	if( ! this.form ) {
		return {};
	}


	// Évènements

	for( let thisEl of this.form.elements ) {
		let inputTimeout;

		// Empêcher les bulles de validation du navigateur
		thisEl.addEventListener( 'invalid', function( event ) {
			event.preventDefault();
		} );

		// Mettre à jour chaque champ quand on fait quelque chose avec
		[ 'input', 'change' ].forEach( function( eventName ) {

			thisEl.addEventListener( eventName, function( event ) {
				// On ne déclenche la validation qu'après un court délai (sinon une même action pourrait valider plusieurs fois, et ça flasherait quand on tape)
				if( inputTimeout ) {
					clearTimeout( inputTimeout );
				}

				inputTimeout = setTimeout( function() {
					that.updateClasses( thisEl );
					that.updateMessages( thisEl );
				}, that.args.eventDelay );
			} );
		} );
	}

	// Empêcher de soumettre si le form est invalide
	this.form.addEventListener( 'submit', function( event ) {
		if( ! event.target.checkValidity() ) {
			event.preventDefault();

			that.form.classList.add( that.args.classInvalidTmp );

			if( that.submitButtons.length > 0 ) {
				that.submitButtons[0].addEventListener( 'animationend', function invalidAnimEnd( event ) {
					that.form.classList.remove( that.args.classInvalidTmp );
					event.target.removeEventListener( 'animationend', invalidAnimEnd );
				} );
			}
		}

		that.updateClasses();
		that.updateMessages();
		that.goToFirstInvalid();
	} );
}

export default FormValidation;